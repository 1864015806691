import { throttle } from '@/util/util';

export default class Floating {

  #root;
  #throttleMs = 100;
  #previousTransformState = '';

  #onEnterHandler = this.#onEnter.bind(this);
  #onMoveHandler = this.#onMove.bind(this);
  #onLeaveHandler = this.#onLeave.bind(this);

  constructor(root) {
    this.#root = root;
    this.#addEventListeners();
  }

  #addEventListeners() {
    this.#root.addEventListener('mouseenter', this.#onEnterHandler);
    this.#root.addEventListener('mousemove', throttle(this.#onMoveHandler, this.#throttleMs));
    this.#root.addEventListener('mouseleave', this.#onLeaveHandler);
  }

  #onEnter(e) {
    // Store initial transform state
    this.#previousTransformState = this.#root.style.transform;
    this.#root.classList.add('Floating--active');
  }

  #onMove(e) {
    e.stopPropagation()

    const { offsetX, offsetY, srcElement } = e;
    const { clientWidth, clientHeight } = srcElement;

    let x = ((offsetX * 2) - clientWidth) / 2;
    let y = ((offsetY * 2) - clientHeight) / 2;
    
    const rotateX = (x / 20).toFixed(2);
    const rotateY = ((y *= -1) / 20).toFixed(2);
  
    this.#root.style.transform = `perspective(${clientWidth}px) rotateY(${rotateX}deg) rotateX(${rotateY}deg)`;
  }

  #onLeave(e) {
    // const defaultState = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`
    this.#root.style.transform = this.#previousTransformState;    
    setTimeout(() => {
      this.#root.style.transform = this.#previousTransformState;
      this.#root.classList.remove('Floating--active');
    }, 
      this.#throttleMs + 1
    );
  }

}