
import Component from '@/EJENDOMcore/Component';
import VideoController from './VideoController';

export default class EmbedVideo extends Component {

  element
  #root;
  #iframe;
  #playBtn;
  #externalPlayBtn;
  #posterElm
  #isPlaying = false;

  #cursorIdle = true;
  #wrapperCenterX = 0;
  #wrapperCenterY = 0;
  #cursorCenterX = 0;
  #cursorCenterY = 0;
  #mouseX = 0;
  #mouseY = 0;

  #onClickHandler = this.#onPlay.bind(this);
  #onEnterHandler = this.#onEnter.bind(this);
  #onMoveHandler = this.#onMove.bind(this);
  #onLeaveHandler = this.#onLeave.bind(this);

  constructor(element) {
    super(element);

    this.element = element;
    
    this.#getElements();
    if(this.#posterElm && (this.#playBtn || this.#externalPlayBtn)) {
      this.#getDimensions();
      this.#addEventListeners();
    }
  }

  #getElements() {
    this.#root = this.element;
    this.#iframe = this.#root.querySelector('iframe');
    this.#playBtn = this.#root.querySelector('.EmbedVideo__playbtn');
    this.#posterElm = this.#root.querySelector('.EmbedVideo__poster');
    this.#externalPlayBtn = document.getElementById(this.#root.dataset.videoId);
    this.controller = new VideoController(this.#iframe);
  }

  #getDimensions() {
    this.#wrapperCenterX = this.#posterElm.offsetWidth / 2;
    this.#wrapperCenterY = this.#posterElm.offsetHeight / 2;
    this.#cursorCenterX = this.#playBtn.offsetWidth / 2;
    this.#cursorCenterY = this.#playBtn.offsetHeight / 2;
    this.#mouseX = this.#wrapperCenterX - this.#cursorCenterX;
    this.#mouseY = this.#wrapperCenterY - this.#cursorCenterY;

    // Initial playBtn (cursor) position
    this.#playBtn.style.transform = `translate(${this.#mouseX}px, ${this.#mouseY}px)`;
  }

  #addEventListeners() {
    this.#playBtn.addEventListener('click', this.#onClickHandler);
    if(this.#externalPlayBtn) {
      this.#externalPlayBtn.addEventListener('click', this.#onClickHandler);
    }

    this.#posterElm.addEventListener('mouseenter', this.#onEnterHandler);
    this.#posterElm.addEventListener('mousemove', this.#onMoveHandler);
    this.#posterElm.addEventListener('mouseleave', this.#onLeaveHandler);
  }

  #onPlay() {
    if(this.#isPlaying) {
      this.#root.classList.remove('EmbedVideo--playing');
      this.controller.pause();
      this.#isPlaying = false;
    } else {
      this.#root.classList.add('EmbedVideo--playing');
      this.controller.play();
      this.#isPlaying = true;
    }
  }

  #onEnter() {
    this.#cursorIdle = false;
    this.#setCursorClass();
  }

  #onMove(e) {
    const { clientY, clientX } = e;
    const wrapperBounds = this.#posterElm.getBoundingClientRect();
    const x = clientX - wrapperBounds.left;
    const y = clientY - wrapperBounds.top;

    this.#mouseX = x - this.#cursorCenterX;
    this.#mouseY = y - this.#cursorCenterY;

    this.#setCursorPosition();
  }

  #onLeave() {
    this.#mouseX = this.#wrapperCenterX - this.#cursorCenterX;
    this.#mouseY = this.#wrapperCenterY - this.#cursorCenterY;
    this.#cursorIdle = true;
    this.#setCursorPosition();
    this.#setCursorClass();
  }

  #setCursorPosition() {
    this.#playBtn.style.transform = `translate(${this.#mouseX}px, ${this.#mouseY}px)`;
  }

  #setCursorClass() {
    this.#cursorIdle ? this.#playBtn.classList.add('Button--play--idle') : this.#playBtn.classList.remove('Button--play--idle');
  }

}