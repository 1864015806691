import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const ThreeScreensSlideInTimeline = (wrapper, animateElm) => gsap.timeline({
  scrollTrigger: {
    trigger: wrapper,
    markers: false,
    start: 'top center',
    end: 'bottom bottom'
  }
}).fromTo(animateElm, {
  y: 100,
  opacity: 0,
}, {
  duration: 0.7,
  y: 0,
  opacity: 1,
  ease: '0.08,0.82,0.17,1',
  stagger: 0.25
});

export const ThreeImagesSlideInTimeline = (wrapper, animateElm) => gsap.timeline({
  scrollTrigger: {
    trigger: wrapper,
    markers: false,
    start: 'top center',
    end: 'bottom bottom'
  }
}).fromTo(animateElm, {
  y: 50,
  opacity: 0,
}, {
  duration: 0.7,
  y: 0,
  opacity: 1,
  ease: '0.08,0.82,0.17,1',
  stagger: 0.25
});

export const GridStagger = (wrapper, animateElm) => gsap.timeline({
  scrollTrigger: {
    trigger: wrapper,
    markers: false,
    start: 'top 80%',
    end: 'bottom bottom'
  }
}).fromTo(animateElm, {
  y: 50,
  opacity: 0,
  scale: 0.85
}, {
  duration: 0.7,
  scale: 1,
  y: 0,
  opacity: 1,
  ease: '0.08,0.82,0.17,1',
  stagger: 0.2,
});

export const FeatureParralax = (wrapper, animateElm) => gsap.timeline({
  scrollTrigger: {
    trigger: wrapper,
    markers: false,
    scrub: true
  }
}).fromTo(animateElm, {
  y: '40%',
}, {
  y: '-20%',
}).fromTo(wrapper.querySelector('img'), {
  y: '20%',
}, {
  y: '-20%',
}, "<");
