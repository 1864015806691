import ControlledElement from '../EJENDOMcore/ControlledElement';

export default class PersonCard extends ControlledElement {

  #container;

  constructor(element) {
    super(element);
    this.#container = this.element.parentElement;
  }

  open() {
    this.on();
  }

  close() {
    this.off();
  }

  onOn() {
    this.element.removeAttribute('hidden');
  }

  onOff() {
    this.element.setAttribute('hidden', '');
  }
}
