import ControlledElement from '../EJENDOMcore/ControlledElement';
import FocusTrap from '../EJENDOMcore/FocusTrap';

let allDropdowns;

export const initDropdowns = (triggerAttr = 'data-toggle-modal') => {

  const dropdowns = [];

  [...document.querySelectorAll('.Dropdown__body')].forEach(el => {
    dropdowns.push(new DropDown(el));
  });

  allDropdowns = dropdowns;
}

export class DropDown extends ControlledElement {
  //
  // Events
  //
  static EVENT_OPEN = "dropdown_open";
  static EVENT_CLOSE = "dropdown_close";
  static CLICKABLES = ['A'];

  #focusTrap;
  #container;

  constructor(element) {
    super(element);
    this.#container = this.element.parentElement;

    this.#focusTrap = new FocusTrap(this.element);
    this.onBodyClickHandler = this.onBodyClick.bind(this);
    this.onClickOtherDropDownHandler = this.onClickOtherDropdown.bind(this);
  }

  onBodyClick(evt) {
    if (this.#container.parentElement.contains(evt.target) && !DropDown.CLICKABLES.includes(evt.target.tagName)) {
      return;
    }

    this.close();
  }

  onClickOtherDropdown() {
    this.close();
  }

  open() {
    this.on();
  }

  close() {
    this.off();
  }

  onOn() {
    // Check if any previous dropdowns are open and if so close them
    const dropdowns = getAllDropdowns();
    dropdowns.forEach(dropdown => {
      if(dropdown.isOn && dropdown.name !== this.name) {
        dropdown.onClickOtherDropDownHandler();
      }
    });

    this.element.removeAttribute('hidden');
    document.addEventListener('click', this.onBodyClickHandler, false);
    this.events.emit(DropDown.EVENT_OPEN);
    this.#focusTrap.trapFocus();
  }

  onOff() {
    this.element.setAttribute('hidden', '');
    document.removeEventListener('click', this.onBodyClickHandler, false);
    this.events.emit(DropDown.EVENT_CLOSE);
    this.#focusTrap.releaseFocus();
  }
}


export const getAllDropdowns = () => {
  return allDropdowns;
}