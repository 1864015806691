import axios from "axios";

export default class MailchimpSubscribeForm {
  #root = null;
  #form = null;
  #submitBtn = null;
  #loading = false;
  #submitted = false;
  #submitTxt = "";
  #submitLoadingTxt = "";

  #OnSubmitHandler = this.#onSubmit.bind(this);

  constructor(root) {
    this.#root = root;
    this.#form = this.#root;
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#form = this.#root;
    this.#submitBtn = this.#root.querySelector('input[type="submit"]');
    this.#submitTxt = this.#submitBtn.value;
    this.#submitLoadingTxt = this.#submitBtn.dataset.loadingText;
  }

  #addEventListeners() {
    this.#form.addEventListener("submit", this.#OnSubmitHandler);
  }

  #onSubmit(e) {
    e.preventDefault();
    if (this.#loading || this.#submitted) {
      return;
    }

    this.#loading = true;
    this.#submitBtn.value = this.#submitLoadingTxt;
    this.#form.classList.add("Form--sending");

    const formData = new FormData(this.#form);
    const name = formData.get("name");
    const email = formData.get("email");

    axios({
      method: "POST",
      url: "/mailchimp/api/subscribe",
      params: {
        name,
        email,
      },
    })
      .then((response) => {
        if (response.data.error) {
          // console.error(response.data.error);
          alert("Noget gik galt, prøv igen.");
        } else {
          this.#submitted = true;
          this.#form.classList.add("Form--submitted");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        // Always
        this.#loading = false;
        this.#form.classList.remove("Form--sending");
        this.#submitBtn.value = this.#submitTxt;
      });
  }
}
