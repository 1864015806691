import axios from 'axios';
import { serialize } from '../util/util';

export default class Form {

  #root = null;
  #form = null;
  #submitBtn = null;
  #loading = false;
  #submitted = false;
  #submitTxt = '';
  #submitLoadingTxt = '';

  #OnSubmitHandler = this.#onSubmit.bind(this);

  constructor(root) {
    this.#root = root;
    this.#form = this.#root;
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#form = this.#root;
    this.#submitBtn = this.#root.querySelector('input[type="submit"]');
    this.#submitTxt = this.#submitBtn.value;
    this.#submitLoadingTxt = this.#submitBtn.dataset.loadingText;
  }

  #addEventListeners() {
    this.#form.addEventListener('submit', this.#OnSubmitHandler);
  }

  #onSubmit(e) {
    e.preventDefault();
    if(this.#loading) {return;}

    this.#loading = true;
    this.#submitBtn.value = this.#submitLoadingTxt;
    this.#form.classList.add('Form--sending');

    // Recaptcha check
    grecaptcha.ready(() => {
      grecaptcha.execute('6LeTkQojAAAAAETYnaA4B17c6aCS3uqU_mYiQIwV', {action: 'submit'}).then((token) => {
        // Validate reCAPTCHA token
        axios({
          method: 'POST',
          url: '/googlerecaptcha/api/validate-token',
          data: {
            token
          }
        })
        .then((response) => {
          // reponse.data: reCAPTCHA result
          // console.log(response.data);
          const validated = response.data.success;
          const spamScore = parseFloat(response.data.score);

          // check if reCAPTCHA believes this is spam or valid data
          if(validated && spamScore > 0.5) {

            // Change to correct form action
            let actionInput = document.querySelector('input[name="action"]');
            actionInput.setAttribute('value', 'contact-form/send');

            // Data to send
            let formData = serialize(this.#form);
  
            // Post to craft contact form endpoint
            axios({
              method: 'POST',
              url: '/',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              data: formData,
            })
            .then((response) => {
              // Success
              this.#submitted = true;
              this.#form.classList.add('Form--submitted');
            })
            .catch((error) => {
              // Error
              console.log(error.response);
              alert('Noget gik galt... Prøv igen eller kontakt info@ejendom.com');
            })
            .then(() => {
              // Always
              this.#loading = false;
              this.#form.classList.remove('Form--sending');
              this.#submitBtn.value = this.#submitTxt ;
            })
          } else {
            this.#loading = false;
            this.#form.classList.remove('Form--sending');
            this.#submitBtn.value = this.#submitTxt ;
            alert('Noget gik galt... Prøv igen eller kontakt os via vores kontaktoplysninger her på siden.');
          }
        })
        .catch((error) => alert('Noget gik galt... Prøv igen eller kontakt os via vores kontaktoplysninger her på siden.'))
      });
    });
  }

}
