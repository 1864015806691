// Import favicons
import faviconAlternate from "./images/favicon.png";
import favicon from "./images/favicon.svg";
import favicon16 from "./images/favicon-16x16.png";
import favicon32 from "./images/favicon-32x32.png";
import appleTouchIcon from "./images/apple-touch-icon.png";
//
// https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import
//
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";
import dialogPolyfill from "dialog-polyfill";

//
// Bundle fonts
//
import "@/fonts/national/national-2-web-regular.woff";
import "@/fonts/national/national-2-web-regular.woff2";
import "@/fonts/national/national-2-web-bold.woff";
import "@/fonts/national/national-2-web-bold.woff2";
import "@/fonts/national/national-2-web-extrabold.woff";
import "@/fonts/national/national-2-web-extrabold.woff2";

// Static assets
import "./assets";

// Parts
import TheHeader from "./parts/TheHeader";
import ThePageNavBar from "./parts/ThePageNavBar";

//
// Components
//
import CookieConsent from "./components/CookieConsent";
import { initDropdowns } from "@/components/Dropdown";
import Slider from "@/components/Slider";
import EmbedVideo from "@/components/EmbedVideo";
import Video from "@/components/Video";
import Floating from "@/components/Floating";
import ScrollSections from "@/components/ScrollSections";
import ScrollAnimation from "@/components/ScrollAnimation";
import Form from "@/components/Form";
import PersonCard from "@/components/PersonCard";
import Milestones from "@/components/Milestones";
import Accordion from "@/components/Accordion";
import Modal from "@/components/Modal";
import MailchimpSubscribeForm from "@/components/MailchimpSubscribeForm";
import { registerContainerLink } from "@/util/registerContainerLink";

//
// Application
//
const Ejendom = {
  // Options / globals
  body: null,
  cookieConsentForm: null,
  scrollY: 0,
  bookDemoDialogue: null,
  dropdowns: [],
  recaptchaLoaded: false,

  init() {
    this.body = document.body;
    this.TheHeader = TheHeader.getInstance();
    this.ThePageNavBar = ThePageNavBar.getInstance();
    this.TheNav = document.getElementById("TheMainNavigation");
    this.scrollY = window.scrollY;

    // Init cookie consent
    this.getDelayedElements();

    // Init dropdowns
    this.dropdowns = initDropdowns();

    // Init sliders
    [...this.body.querySelectorAll(".Slider")].forEach((el) => new Slider(el));

    // Init dialogues
    [...this.body.querySelectorAll("dialog")].forEach((el) =>
      dialogPolyfill.registerDialog(el)
    );

    // Init "container links"
    [...document.querySelectorAll("[data-container-link]")].forEach((el) =>
      registerContainerLink(el, el.dataset.containerLink)
    );

    // Init video embeds
    [...this.body.querySelectorAll(".EmbedVideo")].forEach(
      (el) => new EmbedVideo(el)
    );

    // Init videoes
    [...this.body.querySelectorAll(".Video")].forEach((el) => new Video(el));

    // Init scroll sections
    [...this.body.querySelectorAll(".ScrollSections")].forEach(
      (el) => new ScrollSections(el)
    );

    // Init floating
    [...this.body.querySelectorAll(".Floating")].forEach(
      (el) => new Floating(el)
    );

    // Init animations
    [...this.body.querySelectorAll("[data-trigger-animation]")].forEach(
      (el) => new ScrollAnimation(el)
    );

    // Init forms
    [...this.body.querySelectorAll(".Form")].forEach((el) => new Form(el));

    // Init persons
    [...this.body.querySelectorAll(".PersonCard__bio")].forEach((el) => {
      new PersonCard(el);
    });

    // Init milestones
    [...this.body.querySelectorAll(".Milestones")].forEach(
      (el) => new Milestones(el)
    );

    // Init accordions
    [...this.body.querySelectorAll(".Accordion__body")].forEach((el) => {
      new Accordion(el);
    });

    // Init accordions
    [...this.body.querySelectorAll(".Modal")].forEach((el) => {
      new Modal(el);
    });

    // Init book demo CTA
    this.bookDemoDialogue = document.getElementById("CtaDialog");
    this.bookDemoDialogueCloseBtn = document.getElementById("CtaDialogClose");

    if (this.bookDemoDialogue) {
      [...this.body.querySelectorAll("[data-book-demo]")].forEach((el) => {
        // Open dialogue when clicking on book demo btns
        el.addEventListener("click", (e) => {
          this.openCtaDialogue();
        });
      });
      this.bookDemoDialogue.addEventListener("click", (event) => {
        // Close dialogue when clicking dialogue backdrop
        var rect = this.bookDemoDialogue.getBoundingClientRect();
        var isInDialog =
          rect.top <= event.clientY &&
          event.clientY <= rect.top + rect.height &&
          rect.left <= event.clientX &&
          event.clientX <= rect.left + rect.width;
        if (!isInDialog) {
          this.closeCtaDialogue();
        }
      });
      this.bookDemoDialogueCloseBtn.addEventListener("click", () => {
        // Close dialogue when clicking close button
        this.closeCtaDialogue();
      });
    }

    // Init newsletter subscription form
    const mailchimpSubscribeForm = document.getElementById(
      "MailchimpSubscribeForm"
    );
    if (mailchimpSubscribeForm) {
      new MailchimpSubscribeForm(mailchimpSubscribeForm);
    }

    // On browser resize
    window.addEventListener("resize", () => this.onResize());

    // Google reCAPTCHA v3 needed for forms (adding after interaction to avoid page speed penalty)
    window.addEventListener("scroll", () => {
      let scroll = window.scrollY;
      if (scroll > 0 && !this.recaptchaLoaded) {
        // Load on scroll
        this.loadReCAPTCHA();
      }
    });
  },

  getDelayedElements() {
    // Elements injected via AJAX to TWIG templates and may not be immediately available
    setTimeout(() => {
      this.cookieConsentForm = document.getElementById("CookieConsent");
      if (this.cookieConsentForm) {
        CookieConsent.init(this.cookieConsentForm);
      }
    }, 1000);
  },

  initOnLoad() {},

  onResize() {
    // Sync height css variable
    document.documentElement.style.setProperty(
      "--window-inner-height",
      `${window.innerHeight}px`
    );
  },

  preventDefault(e) {
    e.preventDefault();
  },

  loadReCAPTCHA() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.src =
      "https://www.google.com/recaptcha/api.js?render=6LeTkQojAAAAAETYnaA4B17c6aCS3uqU_mYiQIwV";
    recaptchaScript.async = true;
    document.body.appendChild(recaptchaScript);
    this.recaptchaLoaded = true;
    // console.log("reCAPTCHA v3: ", this.recaptchaLoaded);
  },

  openCtaDialogue() {
    if (!this.recaptchaLoaded) {
      // If recaptcha was not loaded by scroll then load it now
      this.loadReCAPTCHA();
    }
    this.scrollY = window.scrollY;
    this.bookDemoDialogue.showModal();
    document.documentElement.classList.add("modal-open");
    // this.bookDemoDialogue.addEventListener("pointermove", this.preventDefault);
    // this.bookDemoDialogue.addEventListener("touchmove", this.preventDefault);
    if (window && window.ga) {
      ga("send", {
        hitType: "event",
        eventCategory: "CTA",
        eventAction: "click",
        eventLabel: "Book demo",
        value: 100,
      });
    }
  },

  closeCtaDialogue() {
    this.bookDemoDialogue.close();
    document.documentElement.classList.remove("modal-open");
    // this.bookDemoDialogue.removeEventListener("pointermove", this.preventDefault);
    // this.bookDemoDialogue.removeEventListener("touchmove", this.preventDefault);
    // restore scroll position
    window.scrollTo(0, this.scrollY);
  },
};

document.addEventListener("DOMContentLoaded", () => Ejendom.init());
window.addEventListener("load", () => Ejendom.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
