import ControlledElement from "../EJENDOMcore/ControlledElement";

export default class Modal extends ControlledElement {
  #root;
  #exitBtn;
  #onClickHandler = this.#onDialogueClick.bind(this);

  constructor(element) {
    super(element);
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#root = this.element;
    this.#exitBtn = this.#root.querySelector(".Modal__close");
  }

  #addEventListeners() {
    this.#root.addEventListener("click", this.#onClickHandler);
    this.#exitBtn.addEventListener("click", () => this.close());
  }

  open() {
    this.on();
  }

  close() {
    this.off();
  }

  onOn() {
    this.element.showModal();
    document.documentElement.classList.add("modal-open");
  }

  onOff() {
    this.element.close();
    document.documentElement.classList.remove("modal-open");
  }

  #onDialogueClick(event) {
    var rect = this.#root.getBoundingClientRect();

    var clickIsInsideDialogue =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;

    !clickIsInsideDialogue && this.close();
  }
}
