import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ScrollSections {

  #root;
  #milestones;
  #illustrations;
  #activeIllustration;

  constructor(root) {
    this.#root = root;
    this.#getElements();
    this.#setupTimelines();
  }

  #getElements() {
    this.#illustrations = [...this.#root.querySelectorAll('div[data-milestone-illustration]')];
    this.#milestones = [...this.#root.querySelectorAll('.Milestone')];
    this.#activeIllustration = this.#illustrations[0];
  }

  #setupTimelines() {
    this.#milestones.forEach(milestone => {
      gsap.timeline({
        scrollTrigger: {
          trigger: milestone,
          start: 'top center',
          end: 'bottom center',
          markers: false,
          onEnter: () => this.#showIllustration(milestone.dataset.illustration),
          onEnterBack: () => this.#showIllustration(milestone.dataset.illustration)
        }
      })
    });
  }

  #showIllustration(illustrationName) {
    this.#activeIllustration.classList.remove('active');
    this.#illustrations.forEach(illustration => illustration.dataset.milestoneIllustration === illustrationName ? this.#activeIllustration = illustration : false);
    this.#activeIllustration.classList.add('active');
  }

}