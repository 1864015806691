import Element from '../EJENDOMcore/Element';
import Overlay from '../EJENDOMcore/Overlay';

// General singleton class.
export default class TheHeader extends Element {
  static #instance;

  constructor() {
    super(document.querySelector('#TheHeader'));

    // Handle signleton
    if (TheHeader.#instance) {
      return TheHeader.#instance;
    }

    TheHeader.#instance = this;

    this.#init();
  }

  #init() {
    const navElm = document.getElementById('TheMainNavigation');
    if(navElm) {
      this.navigation = new Overlay(navElm);
      this.trigger = document.querySelector('#TheMainNavTrigger');
      this.events.subscribe(this.navigation, Overlay.EVENT_OPEN, () => {
        this.trigger.setAttribute('aria-label', 'Luk navigation');
      });
      this.events.subscribe(this.navigation, Overlay.EVENT_CLOSE, () => {
        this.trigger.setAttribute('aria-label', 'Åben navigation');
      });
    }
  }

  static getInstance() {
    if (TheHeader.#instance) {
      return TheHeader.#instance;
    }

    TheHeader.#instance = new TheHeader(document.querySelector('#TheHeader'));
    return TheHeader.#instance;
  }
}
