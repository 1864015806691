import Component from "@/EJENDOMcore/Component";
import Swiper, { Navigation, Autoplay, EffectFade } from "swiper";

Swiper.use([Navigation, Autoplay, EffectFade]);

const defaultOptions = {
  slidesPerView: 3,
  grabCursor: true,
  centeredSlides: true,
  freeMode: true,
  loop: true,
  spaceBetween: 30,
  autoplay: {
    disableOnInteraction: false,
    delay: 1,
  },
  speed: 5000,
  freeModeMomentum: false,
  delay: 0,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1110: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
};

const testimonialOptions = {
  spaceBetween: 30,
  allowTouchMove: false,
  autoplay: false,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1110: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
};

const testimonialBigOptions = {
  spaceBetween: 30,
  allowTouchMove: false,
  autoplay: false,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  loop: true,
};

const logoSliderOptions = {
  grabCursor: true,
  centeredSlides: true,
  freeMode: true,
  loop: true,
  spaceBetween: 20,
  autoplay: {
    disableOnInteraction: false,
    delay: 1,
  },
  speed: 5000,
  freeModeMomentum: false,
  delay: 0,
  slidesPerView: "auto",
};

export default class Slider extends Component {
  options = {};
  hasControls = false;
  slideIndex = 0;
  isBeginning = true;
  isEnd = false;

  onSlideChangeHandler = this.onSlideChange.bind(this);
  onNavClickHandler = this.onNavClick.bind(this);

  constructor(root) {
    super(...arguments);

    this.root = root;
    this.sliderElm = this.root.querySelector(".Slider__inner");
    this.sliderControlsElm = this.root.querySelector(".Slider__controls");
    this.hasControls = this.sliderControlsElm ? true : false;

    this.getConfig(this.root.dataset.sliderConfig);
    this.initSlider(this.sliderElm, this.options);
  }

  getConfig(config) {
    switch (config) {
      case "testimonials":
        this.options = testimonialOptions;
        this.isTestimonialSlider = true;
        break;
      case "testimonialsBig":
        this.options = testimonialBigOptions;
        this.isTestimonialSlider = true;
        break;
      case "logoSlider":
        this.options = logoSliderOptions;
        break;
      default:
        this.options = defaultOptions;
    }
  }

  initSlider(sliderElm, options) {
    if (this.hasControls) {
      const nextBtn = this.sliderControlsElm.querySelector(".Slider__next");
      const prevBtn = this.sliderControlsElm.querySelector(".Slider__prev");
      const navigation = {
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
          disabledClass: "Slider__nextprev--disabled",
        },
      };
      options = { ...options, ...navigation };
      nextBtn.addEventListener("click", this.onNavClickHandler);
      prevBtn.addEventListener("click", this.onNavClickHandler);
    }

    this.swiper = new Swiper(sliderElm, options);
    this.swiper.on("slideChange", this.onSlideChangeHandler);

    if (this.root.dataset.sliderConfig === "testimonials") {
      this.root.classList.add("TestimonialSliderBig--running");
    }
  }

  onSlideChange(e) {
    this.isBeginning = this.swiper.isBeginning;
    this.isEnd = this.swiper.isEnd;
    this.slideIndex = this.swiper.activeIndex;
  }

  onNavClick() {
    if (this.isTestimonialSlider) {
      this.root.classList.remove("TestimonialSliderBig--running");
    }
  }
}
