import Component from "@/EJENDOMcore/Component";

export default class Video extends Component {
  element;
  #root;
  #videoElm;
  #posterElm;
  #playBtnElm;
  #onClickHandler = this.#onPlay.bind(this);

  constructor(element) {
    super(element);
    this.element = element;
    this.#getElements();
    this.#addEventListeners();
  }

  #getElements() {
    this.#root = this.element;
    this.#videoElm = this.#root.querySelector("video");
    this.#playBtnElm = this.#root.querySelector(".Video__playbtn");
    this.#posterElm = this.#root.querySelector(".Video__poster");
  }

  #addEventListeners() {
    this.#playBtnElm.addEventListener("click", this.#onClickHandler);
  }

  #onPlay() {
    this.#root.classList.add("Video--playing");
    this.#videoElm.play();
  }
}
