import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ScrollSections {

  #root;
  #sections = [];
  #wrapper = null;
  #screen = null;
  #body = null;
  #onWrapperEnterHandler = this.#toggleWrapperClasses.bind(this);
  #onWrapperLeaveHandler = this.#toggleWrapperClasses.bind(this);
  #wrapperSection = '';
  #wrapperSectionBgColor = '';

  constructor(root) {
    this.#root = root;
    this.#getElements();
  }

  #getElements() {
    this.#sections = [...this.#root.querySelectorAll('.ScrollSections__content')];
    this.#wrapper = this.#root;
    this.#body = document.body;
    this.#screen = this.#root.querySelector('.ScrollAnimation__screen');
    this.#screen ? this.#screen.style.transform = `perspective(${this.#root.getBoundingClientRect().width}px) rotateY(0deg) rotateX(0deg) scale3d(1, 1, 1)` : false;
    this.#sections.length ? this.#setupTimelines() : false;
    this.#wrapperSection = this.#root.parentElement.parentElement;
    this.#wrapperSectionBgColor = getComputedStyle(this.#wrapperSection).backgroundColor;
  }

  #setupTimelines() {
    // Wrapper timeline
    gsap.timeline({
      scrollTrigger: {
        trigger: this.#wrapper,
        start: 'top center',
        end: 'bottom center',
        markers: false,
        onEnter: () => this.#onWrapperEnterHandler(true, false),
        onLeave: () => this.#onWrapperLeaveHandler(false, true),
        onEnterBack: () => {this.#onWrapperEnterHandler(true, false); },
        onLeaveBack: () => {this.#onWrapperLeaveHandler(false, false, true); }
      }
    })
    
    // Sections timelines
    this.#sections.forEach(section => {
      const animation = section.dataset.animationTrigger;
      const color = section.dataset.backgroundColor;
      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'bottom center',
          markers: false,
          onEnter: () => this.#activateAnimation(animation, false, color),
          onLeave: () => this.#deActivateAnimation(animation, true),
          onEnterBack: () => this.#activateAnimation(animation, false, color),
          onLeaveBack: () => this.#deActivateAnimation(animation, false)
        }
      })
    });
  }

  #activateAnimation(animation, leave, color) {
    const animationElm = document.body.querySelector(`[data-animation="${animation}"]`);
    if(animationElm) {
      animationElm.classList.add('active');
      // Slighty rotate when entering new section
      function getRandomNumberBetween(min,max){
        return Math.floor(Math.random()*(max-min+1)+min);
      }
      const randomYdeg = getRandomNumberBetween(5, 15);
      const randomXdeg = getRandomNumberBetween(-7, 7);
      this.#screen.style.transform = `perspective(${this.#root.getBoundingClientRect().width}px) rotateY(-${randomYdeg}deg) rotateX(${randomXdeg}deg) scale3d(1, 1, 1)`;

      // Background color
      gsap.to(this.#body, { backgroundColor: color });

      if(leave) {
        animationElm.classList.add('leave');
      } else {
        animationElm.classList.remove('leave');
      }
    }
  }

  #deActivateAnimation(animation, leave) {
    const animationElm = document.body.querySelector(`[data-animation="${animation}"]`);
    if(animationElm) {
      animationElm.classList.remove('active');
      if(leave) {
        animationElm.classList.add('leave');
      } else {
        animationElm.classList.remove('leave');
      } 
    }
  }

  #toggleWrapperClasses(enter, leave, clear) {
    if(enter) {
      this.#wrapper.classList.add('enter');
      this.#wrapper.classList.remove('leave');
      gsap.to(this.#wrapperSection, { backgroundColor: 'transparent' })
    }
    if(leave) {
      this.#wrapper.classList.add('leave');
      this.#wrapper.classList.remove('enter');
      gsap.to(this.#body, { backgroundColor: 'rgb(255 255 255)' })
      
    }
    if(clear) {
      this.#wrapper.classList.remove('leave');
      this.#wrapper.classList.remove('enter');
      gsap.to(this.#body, { backgroundColor: 'rgb(255 255 255)' })
      gsap.to(this.#wrapperSection, { backgroundColor: this.#wrapperSectionBgColor })
    }
  }

}