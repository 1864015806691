import ControlledElement from '../EJENDOMcore/ControlledElement';

export default class Accordion extends ControlledElement {
  constructor(element) {
    super(element);
  }

  onOn() {
    this.element.removeAttribute('hidden');
  }

  onOff() {
    this.element.setAttribute('hidden', '');
  }
}
