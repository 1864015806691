import Element from '../EJENDOMcore/Element';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// General singleton class.
export default class ThePageNavBar extends Element {
  static #instance;

  #indicator;
  #inner;

  constructor() {
    super(document.querySelector('#ThePageNavBar'));

    // Handle singleton
    if (ThePageNavBar.#instance) {
      return ThePageNavBar.#instance;
    }

    ThePageNavBar.#instance = this;

    this.#init();
  }

  #init() {
    document.documentElement.style.scrollBehavior = 'smooth';
    this.#indicator = this.element.querySelector('.ThePageNavBar__indicator');
    this.#inner = this.element.querySelector('.ThePageNavBar__inner');
    
    const navElms = [...this.element.querySelectorAll('a')];
    let sections = [];
    navElms.forEach(el => sections.push(document.body.querySelector(`${el.getAttribute('href')}`)));

    sections.forEach(section => {
      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'bottom center',
          markers: false,
          onEnter: () => this.#highlightNavItem(section.getAttribute('id')),
          onEnterBack: () => this.#highlightNavItem(section.getAttribute('id'))
        }
      })
    });
  }

  #highlightNavItem(href) {
    const navItem = this.element.querySelector(`a[href="#${href}`);
    const left = navItem.getBoundingClientRect().left;
    const innerLeft = this.#inner.getBoundingClientRect().left;
    const width = navItem.getBoundingClientRect().width;
    
    // this.#indicator.style.transform = `translateX(${left - innerLeft}px)`;
    // this.#indicator.style.width = `translateX(${left - innerLeft}px)`;

    this.#indicator.style.cssText = `
      width: ${width}px;
      transform: translateX(${left - innerLeft}px);
    `;

  }

  static getInstance() {
    if (ThePageNavBar.#instance) {
      return ThePageNavBar.#instance;
    }
    const elm = document.querySelector('#ThePageNavBar');

    if(elm) {
      ThePageNavBar.#instance = new ThePageNavBar(document.querySelector('#ThePageNavBar'));
      return ThePageNavBar.#instance;
    }
  }
}
