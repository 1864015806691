import FocusTrap from "../EJENDOMcore/FocusTrap";

const CookieConsent = {
  root: null,
  loading: false,

  init(root) {
    if (!root) {
      return;
    }

    // Elements
    this.root = root;
    this.form = document.getElementById("CookieConsentForm");
    this.csrfInput = this.form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

    // Dynamically fetch csrf input
    fetch("/actions/blitz/csrf/token")
      .then((result) => {
        return result.text();
      })
      .then((result) => {
        this.csrfInput.value = result;
      });

    this.submitAllBtn = document.getElementById("CookieConsentAll");
    this.submitAllSecondaryBtn = document.getElementById(
      "CookieConsentAllSecondary"
    );
    this.submitSaveBtn = document.getElementById("CookieConsentSave");
    this.openSettingsBtn = document.getElementById("CookieConsentSettings");
    this.settings = this.root.querySelector(".CookieConsent__settings");
    this.moreInfoToggles = this.root.querySelectorAll(
      ".CookieConsent__group__moreinfo"
    );
    this.consentLabels = this.root.querySelectorAll(
      ".CookieConsent__group__consent"
    );
    this.closeSettingsBtn = this.root.querySelector(".CookieConsent__close");

    // Focustrap
    this.focusTrap = new FocusTrap(this.settings);

    // Event listeners
    this.submitAllListener = this.submitAll.bind(this);
    this.submitSaveListener = this.submitSave.bind(this);
    this.openSettingsListener = this.openSettings.bind(this);
    this.moreInfoClickListener = this.onMoreInfoClick.bind(this);
    this.moreInfoKeypressListener = this.onMoreInfoKeypress.bind(this);
    this.labelKeyPressListener = this.onLabelKeypress.bind(this);
    this.closeSettingsListener = this.closeSettings.bind(this);

    // Attach listeners
    this.submitAllBtn.addEventListener("click", this.submitAllListener);
    this.submitAllSecondaryBtn.addEventListener(
      "click",
      this.submitAllListener
    );
    this.submitSaveBtn.addEventListener("click", this.submitSaveListener);
    this.openSettingsBtn.addEventListener("click", this.openSettingsListener);
    this.closeSettingsBtn.addEventListener("click", this.closeSettingsListener);
    [...this.moreInfoToggles].forEach((toggle) =>
      toggle.addEventListener("click", () => this.moreInfoClickListener(toggle))
    );
    [...this.moreInfoToggles].forEach((toggle) =>
      toggle.addEventListener("keypress", this.moreInfoKeypressListener)
    );
    [...this.consentLabels].forEach((label) =>
      label.addEventListener("keypress", this.labelKeyPressListener)
    );
  },

  openSettings(event) {
    event.preventDefault();
    this.root.classList.add("CookieConsent--open");
    document.body.classList.add("cookie-consent-settings-open");
    this.focusTrap.trapFocus();
  },

  closeSettings(event) {
    event.preventDefault();
    this.root.classList.remove("CookieConsent--open");
    document.body.classList.remove("cookie-consent-settings-open");
    this.focusTrap.releaseFocus();
  },

  submitAll(event) {
    // Submit form and enable all consents
    event.preventDefault();
    if (this.loading) {
      // Previous request in progress
      return;
    }
    this.setLoading();

    let form = this.form;

    for (let i = 0; i < form.elements.length; i++) {
      if (
        form.elements[i].type == "checkbox" &&
        !form.elements[i].id.startsWith("more-info")
      ) {
        if (form.elements[i].checked == false) {
          form.elements[i].checked = true;
        }
      }
    }

    let data = this.serializeForm(form);
    let xhr = new XMLHttpRequest();
    xhr.open("POST", form.dataset.url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(data.concat("&acceptAll=true"));

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) console.log("Error: " + xhr.status);
        else if (
          form.hasAttribute("data-refresh") &&
          form.dataset.refresh === "true" &&
          form.hasAttribute("data-refreshtime")
        ) {
          this.refresh(form.dataset.refreshtime);
        } else {
          this.hide();
        }
      }
    };
  },

  submitSave(event) {
    // Submit form with selected consents
    event.preventDefault();
    if (this.loading) {
      // Previous request in progress
      return;
    }
    this.setLoading();

    let form = this.form;
    let data = this.serializeForm(form);

    let xhr = new XMLHttpRequest();
    xhr.open("POST", form.dataset.url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) console.log("Error: " + xhr.status);
        else if (
          form.hasAttribute("data-refresh") &&
          form.dataset.refresh === "true" &&
          form.hasAttribute("data-refreshtime")
        ) {
          this.refresh(form.dataset.refreshtime);
        } else {
          this.hide();
        }
      }
    };
  },

  setLoading() {
    this.loading = true;
    this.root.classList.add("CookieConsent--loading");
  },

  hide() {
    this.root.classList.add("CookieConsent--submitted");
    this.loading = false;
  },

  // Serialize form helper function
  serializeForm(form) {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i];

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (
        !field.name ||
        field.disabled ||
        field.type === "file" ||
        field.type === "reset" ||
        field.type === "button"
      )
        continue;

      // If a multi-select, get all selections
      if (field.type === "select-multiple") {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(
            encodeURIComponent(field.name) +
              "=" +
              encodeURIComponent(field.options[n].value)
          );
        }
      }

      // Convert field data to a query string
      else if (
        (field.type !== "checkbox" && field.type !== "radio") ||
        field.checked
      ) {
        serialized.push(
          encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value)
        );
      }
    }

    return serialized.join("&");
  },

  refresh(ms) {
    setTimeout(function () {
      location.reload();
    }, ms);
  },

  onMoreInfoClick(el) {
    el.getAttribute("aria-pressed") === "false"
      ? el.setAttribute("aria-pressed", "true")
      : el.setAttribute("aria-pressed", "false");
  },

  onMoreInfoKeypress(e) {
    if (e instanceof KeyboardEvent && (e.key === "Enter" || e.key === " ")) {
      e.preventDefault();
      const toggleLabel = e.target;
      const toggleInput = document.getElementById(
        toggleLabel.getAttribute("for")
      );
      toggleInput.checked = !toggleInput.checked;
      toggleLabel.getAttribute("aria-pressed") === "false"
        ? toggleLabel.setAttribute("aria-pressed", "true")
        : toggleLabel.setAttribute("aria-pressed", "false");
    }
  },

  onLabelKeypress(e) {
    if (e instanceof KeyboardEvent && (e.key === "Enter" || e.key === " ")) {
      e.preventDefault();
      const label = e.target;
      const input = document.getElementById(label.getAttribute("for"));
      input.checked = !input.checked;
    }
  },
};

export default CookieConsent;
